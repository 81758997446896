<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center">
    <template v-slot:button-content>
      <country-flag
        :country="country(languageCode)"
        size="normal"
        class="m0 p0"
      ></country-flag>
    </template>
    <b-dropdown-item
      v-for="language in languageCodes"
      :key="language"
      :active="language == languageCode"
      @click="onClick(language)"
      link-class="d-flex align-items-center"
      ><country-flag
        :country="country(language)"
        size="small"
        class="m0 p0"
      ></country-flag
      ><span class="pl-2">{{ language }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import CountryFlag from "vue-country-flag";
import * as Utility from "../utilities.js";

export default {
  components: {
    CountryFlag,
  },
  created() {
    this.fetchData();
  },
  methods: {
    country(languageCode) {
      return Utility.getCountryCode(languageCode);
    },
    fetchData() {
      this.$root.$api.getSets().then((data) => {
        let newSetLanguages = {};
        for (const group of data.setGroups) {
          for (const set of group.sets) {
            newSetLanguages[set.id] = set.uniqueLanguageCodes;
          }
        }

        this.setLanguages = newSetLanguages;
      });
    },
    onClick(languageCode) {
      const params = {
        ...this.$route.params,
        languageCode,
      };

      this.$router.pushSafe({ name: this.$route.name, params });
    },
  },
  computed: {
    setId() {
      return this.$route.params.setId || this.$route.params.setIdA;
    },
    languageCode() {
      return this.$route.params.languageCode;
    },
    languageCodes() {
      return this.setLanguages[this.setId] || [this.languageCode];
    },
  },
  data() {
    return {
      setLanguages: {},
    };
  },
};
</script>
